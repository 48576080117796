import React from 'react'
import Footer from './Footer'
import GlobalStyles from '../styles/GlobalStyles'
import Typography from '../styles/Typography'
import 'normalize.css'
import '../styles/custom-bootstrap.scss'
import Faq from './Faq'
import ContactForm from './ContactForm'

const Layout = ({ children }) => {
    return (
        <>
            <GlobalStyles />
            <Typography />

            {children}

            <Faq />

            <ContactForm />

            <Footer />
        </>
    )
}

export default Layout
