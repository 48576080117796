import React from 'react'
import styled from 'styled-components'

/**
 * @returns {JSX.Element}
 * @constructor
 */
const Footer = () => {
    // We have just a blank little footer here.
    return (
        <StyledFooter className='py-5'/>
    )
}

const StyledFooter = styled.footer`
    background-color: var(--bs-dark);

    a {
        color: var(--bs-gray);
    }

    a:hover {
        color: var(--bs-light);
        text-decoration: none;
    }
`

export default Footer
