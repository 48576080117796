import React from 'react'
import { Container } from 'react-bootstrap'
import FaqItem from './FaqItem'
import NumberedRow from './Layout/NumberedRow'

const Faq = () => {
    return (
        <section id='faq' className='py-5'>
            <Container>
                <NumberedRow number={2}>
                    <h2 className='h1 mb-5'>Usein kysyttyä</h2>
                    <FaqItem
                        question='Mikä Mobiilimaksumuuri -palvelu on?'
                        answer='Mobiilimaksumuuri on uusi ja helppo myydä maksullista sisältöä mahdollisimman helposti ja mutkattomasti matkapuhelimella. Voit valita kertaluonteisen tai toistuvan maksun ilman korttia, pankkitiliä tai rekisteröitymistä! Palvelussa on myös mahdollisuus maksuttomaan kokeiluun ennen sitoutumista.'
                    />
                    <FaqItem
                        question='Mihin palvelu soveltuu?'
                        answer='Digitaalisen premium-sisällön jakeluun matkapuhelinasiakkaille. Mobiilimaksu mahdollistaa tilauksen muutamalla klikkauksella ilman luottokorttitietojen antamista, mikä alentaa huomattavasti ostokynnystä ja mahdollistaa samalla pidemmän sitoutumisajan palveluun!'
                    />
                    <FaqItem
                        question='Mitä hyötyä palvelusta on ja mihin hyöty perustuu?'
                        answer="Palvelu voi auttaa sinua korottamaan asiakkaan elinkaariarvoa (customer's lifetime value). Mobiilimaksumuuripalvelu on erinomainen keino rakentaa pitkiä ja kannattavia asiakassuhteita - jotka ovat myös kaikkein tuottoisimpia. Asiakashankinnan kustannus on usein vakio, mutta tuottoihin vaikuttaa myös kuinka toistuvasti asiakkaasi ostaa. Hyödyt perustuvat myös siihen, että pienten ostosten maksaminen on mahdollisimman helppoa ja mutkatonta."
                    />
                    <FaqItem
                        question='Miten asiakas pystyy lopettamaan palvelun tilauksen?'
                        answer='Uuden tilausjakson kohdalla asiakas saa tekstiviestin, missä mahdollistetaan palvelun peruuttaminen. Peruutus on tehty myös helpoksi.'
                    />
                    <FaqItem
                        question='Kuinka paljon palvelu maksaa?'
                        answer='Palvelussa on kuukausimaksu, sekä jokaisesta maksutapahtumasta veloitetaan transaktiokohtainen maksu. Palvelun kustannukset ovat operaattorilaskutuksen vuoksi hieman luottokorttikustannuksia arvokkaampia.'
                    />
                    <FaqItem
                        question='Voiko asiakas saada hyvityksen maksusta?'
                        answer='Maksutapahtuma voidaan hyvittää asiakkaalle pankkitilille asiakaspalvelun toimesta.'
                    />
                    <FaqItem
                        question='Miten saan Mobiilimaksumuuri -palvelun käyttöön ja mitä se sisältää?'
                        answer='Palvelu liitetään osaksi nykyistä maksupalvelua rajapintayhteyden avulla. Vaihtoehtoisesti palvelu on tilattavissa erillisenä itsenäisenä räätälöitynä maksuratkaisuna.'
                    />
                    <FaqItem
                        question='Onko palvelussa “pienellä painettuja” erityisehtoja?'
                        answer='Ei ole. Maksupalvelu vastaa mahdollisista luottotappioista, sekä valvoo automaattisesti sekä estää mahdollisia väärinkäyttötapauksia.'
                    />
                </NumberedRow>
            </Container>
        </section>
    )
}

export default Faq
