module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mobiilimaksumuuri","short_name":"Mobiilimaksumuuri","description":"Mobiilimaksumuuri on uusi ja helppo myydä maksullista sisältöä mahdollisimman helposti ja mutkattomasti matkapuhelimella.","lang":"fi","icon":"src/images/logos/Mobiilimaksu_SIM.png","start_url":"/","background_color":"#FFF","theme_color":"#fa0019","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8d9f75dd0fb07912b0eed47b9331d543"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
