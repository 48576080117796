import React, { useState } from 'react'
import { Alert, Button, Card, Col, Container, Form, Row } from 'react-bootstrap'
import NumberedRow from './Layout/NumberedRow'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import NetlifiedForm from './Forms/NetlifiedForm'
import styled from 'styled-components'

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ContactForm = () => {
    const { image } = useStaticQuery(graphql`
        query {
            image: file(relativePath: { eq: "contact-section-bg.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 3840
                        height: 500
                        placeholder: BLURRED
                        quality: 80
                        transformOptions: { cropFocus: CENTER, fit: OUTSIDE }
                    )
                }
            }
        }
    `)

    const bgImage = getImage(image.childImageSharp)

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const submitHandler = e => {
        e.preventDefault()

        setError(false)
        setLoading(true)

        let formData = new FormData(e.target)

        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams(formData).toString(),
        })
            .then(response => {
                if (!response.ok) {
                    setError('Voi ei, lomakkeen lähetyksessä tapahtui virhe. Yritä myöhemmin uudelleen!')
                    return
                }

                setSubmitted(true)
            })
            .catch(() => setError('Voi ei, lomakkeen lähetyksessä tapahtui virhe. Yritä myöhemmin uudelleen!'))
            .finally(() => setLoading(false))
    }

    return (
        <ContactFormStyles>
            <BgImage id='contact' tag='section' image={bgImage} className='py-5 darker-background-overlay'>
                <Container>
                    <NumberedRow number={3} numberContainerClassName='bg-success text-white border-0'>
                        <Card body className='border-0 py-4'>
                            {!submitted ? (
                                <>
                                    <h2 className='h1'>Ota yhteyttä</h2>
                                    {error && <Alert variant='danger'>{error}</Alert>}
                                    <NetlifiedForm
                                        onSubmit={submitHandler}
                                        name='contact-form'
                                        subject='Contact request - Paywall'>
                                        <Form.Group className='mb-3' controlId='name'>
                                            <Form.Label visuallyHidden>Nimi</Form.Label>
                                            <Form.Control
                                                type='text'
                                                placeholder='Nimi'
                                                name='name'
                                                className='px-4 fs-6'
                                            />
                                        </Form.Group>
                                        <Form.Group className='mb-3' controlId='phone'>
                                            <Form.Label visuallyHidden>Puhelin</Form.Label>
                                            <Form.Control
                                                type='text'
                                                placeholder='Puhelin'
                                                name='phone'
                                                className='px-4 fs-6'
                                            />
                                        </Form.Group>
                                        <Form.Group className='mb-3' controlId='email'>
                                            <Form.Label visuallyHidden>Sähköposti</Form.Label>
                                            <Form.Control
                                                type='email'
                                                placeholder='Sähköposti'
                                                name='email'
                                                className='px-4 fs-6'
                                            />
                                        </Form.Group>
                                        <Form.Group className='mb-3' controlId='message'>
                                            <Form.Label visuallyHidden>Viesti</Form.Label>
                                            <Form.Control
                                                as='textarea'
                                                rows={3}
                                                placeholder='Viesti'
                                                name='message'
                                                className='px-4 fs-6'
                                                required
                                            />
                                        </Form.Group>

                                        <Row className='d-flex justify-content-end'>
                                            <Col lg='5' className='d-grid'>
                                                <Button
                                                    variant='success'
                                                    type='submit'
                                                    size='lg'
                                                    disabled={loading}
                                                    className='text-uppercase shadow fs-6'>
                                                    Lähetä
                                                </Button>
                                            </Col>
                                        </Row>
                                    </NetlifiedForm>
                                </>
                            ) : (
                                <div>
                                    <span className='h1 mb-3 d-block'>Kiitos!</span>
                                    <p className='mb-4'>Saimme viestisi ja katsomme sitä pian.</p>
                                </div>
                            )}
                        </Card>
                    </NumberedRow>
                </Container>
            </BgImage>
        </ContactFormStyles>
    )
}

export default ContactForm

const ContactFormStyles = styled.div`
    .card {
        padding-right: clamp(0rem, 3vw, 3rem);
        padding-left: clamp(0rem, 3vw, 3rem);
    }
`
